import { Component, OnInit } from '@angular/core';
import { PwaService } from './shared/services/pwa.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import posthog from 'posthog-js';

@Component({
    selector: 'root',
    template: `<router-outlet></router-outlet>`,
    standalone: false
})
export class AppComponent implements OnInit {
  private readonly _navigationEnd$ = this._router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd)
  );
  constructor(
    private pwaService: PwaService,
    private _router: Router
  ) {}

  ngOnInit() {
    this._navigationEnd$.subscribe((event: NavigationEnd) => {
      posthog.capture('$pageview');
    });
  }
}
